import React from "react"
// import AnilKumar from "../images/anil_kumar.png"
// import ArindamLahiri from "../images/arindam_lahiri.jpg"
// import SanjayKumar from "../images/sanjay-kumar.jpeg"
// import "react-multi-carousel/lib/styles.css"
// import hMinister from "../images/Hminister.jpeg"
// import Speaker1 from "../images/Dr.AbhilashaGaur.jpg"
// import DefaultImg from "../images/defaultImage.jpg"
// import SatenderArya from "../images/SatenderArya.jpg"
// import raviSreedharan from "../images/raviSreedharan.jpg"
// import anuMehta from "../images/Anu.jpg"
// import vikrant from "../images/vikrant.jpeg"
// import chandrasekar from "../images/Chandrasekar.jpg"
// import AnjalPrakash from "../images/DrAnjalPrakashhighres.png"
// import Aarushi from "../images/arushi.jpg"
// import Rajiv from "../images/RGProfilePic.jpg"
// import ramanKumar from "../images/RamanSharma.jpeg"
// import sachinGolwalkar from "../images/UWD_CEO_SachinGol.png"
// import anuPrasad from "../images/anu.jpg"
// import Shrutee from "../images/Shrutee2.jpg"
// import sunilWadhwa from "../images/sunilWadhwa.jpg"
// import MonikaHalan from "../images/MonikaHalan.jpeg"
// import srikantMohapatra from "../images/MohapatraSir.jpeg"
// import aayushi from "../images/16thMarch2023Event/images/agenda/aayushi4.jpg"
// import AmarVariawa from "../images/AmarVariawa.png"
// import Peter from "../images/Peter.jpg"
// import sanjayKumarRakesh from "../images/sanjayKumarRakesh.jpg"
// import kantaSingh from "../images/kantaSingh.png"
// import { Row, Col, Card } from "react-bootstrap"

export default function speaker() {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // }

  // const carousalData = [
  //   {
  //     url: hMinister,
  //     name: "Chief Guest: Shri Bhagwanth Khuba",
  //     designation:
  //       " Hon'ble Union Minister of State Ministry of Chemicals & Fertilizers and Ministry of New & Renewable Energy, Government of India",
  //   },
  //   {
  //     url: AnilKumar,
  //     name: "Col. Anil Kumar Pokhriyal, Retd",
  //     designation: "CEO - MEPSC",
  //   },
  //   {
  //     url: ArindamLahiri,
  //     name: "Mr. Arindam Lahiri",
  //     designation: "CEO - ASDC",
  //   },
  //   {
  //     url: Speaker1,
  //     name: "Dr. Abhilasha Gaur",
  //     designation: "COO - Electronics Sector Skills Council Of India",
  //   },
  //   {
  //     url: AmarVariawa,
  //     name: "Mr. Amar Variawa",
  //     designation:
  //       "Country Director Public Affairs, Sustainability & ESG at Michelin India Private limited",
  //   },
  //   {
  //     url: Peter,
  //     name: "D Peter Immanuel",
  //     designation: "Founder & CEO Pepekash Consulting LLP",
  //   },
  //   {
  //     url: SatenderArya,
  //     name: "Dr. Satender Arya",
  //     designation: "CEO - ASCI",
  //   },
  //   {
  //     url: anuMehta,
  //     name: "Ms. Anu Mehta",
  //     designation: "Head - CSR at Honda Cars India Limited",
  //   },
  //   {
  //     url: SanjayKumar,
  //     name: "Dr. Sanjay Kumar",
  //     designation:
  //       "President - Harvard Club of India, Founder - Edjustice Foundation Katihar",
  //   },
  //   {
  //     url: raviSreedharan,
  //     name: "Mr. Ravi Sreedharan",
  //     designation: "Founder - Indian School of Development Management",
  //   },
  //   {
  //     url: vikrant,
  //     name: "Mr. Vikrant Abrol",
  //     designation: "Founder - Indian ESG Network",
  //   },
  //   {
  //     url: chandrasekar,
  //     name: "Dr. Chandrasekar",
  //     designation: "Ex. Director - EdCIL Ministry of Education, Govt. of India",
  //   },
  //   {
  //     url: AnjalPrakash,
  //     name: "Prof. Anjal Prakash",
  //     designation: "Research Director - Indian School of Busines",
  //   },
  //   {
  //     url: srikantMohapatra,
  //     name: "Dr. Srikant Mohapatra",
  //     designation: "Pro-Vice Chancellor, IGNOU",
  //   },
  //   {
  //     url: Rajiv,
  //     name: "Mr. Rajiv Gupta",
  //     designation: "CEO - Tricolite Electrical Industries",
  //   },
  //   {
  //     url: sachinGolwalkar,
  //     name: "Mr. Sachin Golwalkar",
  //     designation: "CEO - United Way Delhi",
  //   },
  //   {
  //     url: MonikaHalan,
  //     name: "Ms. Monika Halan",
  //     designation: "Chairperson, SEBI Advisory Committee for IPEF",
  //   },
  //   {
  //     url: ramanKumar,
  //     name: "Mr. Raman Kumar Sharma",
  //     designation: "Senior Advisor - ASDC, Ex Director - Honda Cars India Ltd",
  //   },
  //   {
  //     url: anuPrasad,
  //     name: "Ms. Anu Prasad",
  //     designation: "Founder & CEO - India Leaders for Social Sector",
  //   },
  //   {
  //     url: Aarushi,
  //     name: "Dr. Aarushi Jain",
  //     designation:
  //       "Policy Director - Bharti Institute of Public Policy, Indian School of Business",
  //   },
  //   {
  //     url: sanjayKumarRakesh,
  //     name: "Shri Sanjay Kumar Rakesh",
  //     designation: "MD and CEO of CSC SPV",
  //   },
  //   // {
  //   //   url: UWD_BoardChair_Pics,
  //   //   name: "Ms. Rina Bharat Kaushal",
  //   //   designation: "Board Chair - United Way Delhi",
  //   // },
  //   {
  //     url: sunilWadhwa,
  //     name: "Mr. Sunil Wadhwa",
  //     designation:
  //       "CFO & MD - Tata Power Delhi Distribution Limited (TPDDL), Founder CEO - Multiple orbits consulting",
  //   },
  //   {
  //     url: kantaSingh,
  //     name: "Ms. Kanta Singh,",
  //     designation: "Deputy Country Representative - UN Women India",
  //   },
  //   {
  //     url: Shrutee,
  //     name: "Ms. Shrutee Ganguly",
  //     designation:
  //       "Principal, Corporate Advisory - ESG & Sustainability, Sattva Consulting",
  //   },
  //   {
  //     url: DefaultImg,
  //     name: "Mr. Gaurav Sarup",
  //     designation:
  //       "Director - ESG, Carbon & Social Performance at Vedanta Resources Limited",
  //   },
  //   {
  //     url: aayushi,
  //     name: "Ms. Aayushi Jauhari",
  //     designation: "Lead, Indian ESG Network",
  //   },
  // ]
  return (
    <div className="px-5">
      <h2 className="text-center mb-5 pt-5 mt-3 fw-bold">Speakers</h2>

      {/* <Row>
        {carousalData.map(data => {
          return (
            <Col className="mt-4" md={3}>
              <Card className="h-100 custom-card">
                <Card.Img
                  variant="top"
                  className="img_fluid"
                  style={{ height: "75%", width: "100%" }}
                  src={data.url}
                />
                <Card.Body className="card-repository">
                  <div className="card-heading fw-bold">{data.name}</div>
                  <p>{data.designation}</p>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row> */}

      {/* <div className="row align-items-center"> */}
      {/* <div className="col-lg-4"> */}
      {/* {carousalData.map(data => {
          return (
            <div className="col-md-3 mt-5">
              <Card className="custom-card h-100">
                <Card.Img
                  variant="top"
                  style={{ height: "75%", width: "100%" }}
                  src={data.url}
                />
                <Card.Body>
                  <div className="card-heading fw-bold">{data.name}</div>
                  <p>{data.designation}</p>
                </Card.Body>
              </Card>
            </div>
          )
        })} */}
      {/* </div> */}
      {/* <div className="col-12 col-md-12 col-lg-9"> */}
      {/* <Carousel
            responsive={responsive}
            // arrows={true}
            infinite={true}
            autoPlay={true}
          > */}
      {/* {carousalData.map(data => {
            return (
              <div className="m-3">
                <Card className="custom-card" style={{ height: "28rem" }}>
                  <Card.Img
                    variant="top"
                    style={{ height: "75%", width: "100%" }}
                    src={data.url}
                  />
                  <Card.Body>
                    <div className="card-heading fw-bold">{data.name}</div>
                    <p>{data.designation}</p>
                  </Card.Body>
                </Card>
              </div>
            )
          })} */}
      {/* </Carousel> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  )
}
